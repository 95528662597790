Advancedformmultiselect.$inject = [
    '$scope'];

export default function Advancedformmultiselect($scope) {
    const ctrl = this;
    ctrl.selected = [];

    ctrl.MultiSelectsettings = {
        scrollable: false,
        enableSearch: false,
        displayProp: 'Name',
        showCheckAll: true,
        showUncheckAll: true,
        selectedToTop: false,
        dynamicTitle: true,
        smartButtonMaxItems: 3
    };

    ctrl.TranslationTexts = {
        checkAll: 'Select All',
        uncheckAll: 'Unselect All'
    }

    ctrl.getData = function () {
        return ctrl.selected;
    };

    ctrl.getStringData = function () {
        // Filter options to get only selected items
        let selectedOptions = ctrl.options.filter(function (option) {
            return ctrl.selected.some(function (selected) {
                return selected.Id === option.Id;
            });
        }); 

        // Map filtered options to get names and join them
        return selectedOptions.map(function (option) {
            return option.Name;
        }).join(', ');
    }

    ctrl.setData = function (options) {
        let namesArray = options.split(', ').map(function (name) {
            return name.trim(); // Trim any extra whitespace
        });

        // Filter options to get only those whose name is in the names array
        ctrl.selected = ctrl.options.filter(function (option) {
            return namesArray.includes(option.Name);
        });

        $scope.$apply();
    };
}
